import { Code, FullScreenLoader, Lang } from '@components';
import {
  alertSelector,
  currentLangSelector,
  setAlertReducer,
  setLangReducer,
  validitySelector,
} from '@slices';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { DefaultLayout } from '@layouts';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import gsap from 'gsap';

const AudioList = dynamic(() => import("./../components/Steps/AudioList"), { ssr: false });
const Intro = dynamic(() => import('./../components/Steps/Intro'), {
  ssr: false,
});


const HomePage = () => {
  const dispatch = useDispatch();
  // Store values
  const currentLang = useSelector(currentLangSelector);
  const validityDate = useSelector(validitySelector);
  const alert = useSelector(alertSelector);
  // AudioPlayer
  const sonar = new Audio('audio/sonar.mp3');
  const tick = new Audio('audio/tick.wav');
  const noise = new Audio('audio/noise.mp3');
  // const submarine = new Audio('audio/submarine.mp3');
  // Ref
  const shapeRef = useRef(null);
  const introRef = useRef(null);
  const langRef = useRef(null);
  const audioListRef = useRef(null);
  // Gsap timelines
  const descendingTimeline = gsap.timeline({ autoRemoveChildren: true });
  const ascendingTimeline = gsap.timeline({
    autoRemoveChildren: true,
    repeat: 0,
  });
  // State
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [step, setStep] = useState(null);
  const [showStart, setShowStart] = useState(false);

  // Events
  const onVideoLoaded = () => {
    setVideoLoaded(true);
    setStep('start');
  };

  const onShowLangStep = () => {
    setStep('lang');
    shapeRef?.current?.classList?.remove('shaking');
    noise.pause();
    sonar.play();
    descendingTimeline.fromTo(
      shapeRef.current,
      {
        top: shapeRef?.current?.getBoundingClientRect()?.y,
        filter: 'blur(5px)',
        ease: 'sine.inOut',
      },
      {
        top: '200vh',
        filter: 'blur(160px)',
        ease: 'sine.inOut',
        duration: 5,
      }
    );
  };

  const onClickLang = (lang) => {
    tick.play();
    dispatch(setLangReducer(lang));
    console.log({
      validity: new Date(validityDate),
      today: new Date(),
    });

    if (validityDate !== null) {
      new Date() > new Date(validityDate) ? setStep('code') : setStep('audio');
    } else setStep('code');
  };

  useEffect(() => {
    // Disalbe alert for security
    if (alert) dispatch(setAlertReducer(false));

    if (videoLoaded && step === 'start') {
      noise.play();
      descendingTimeline.fromTo(
        shapeRef.current,
        {
          top: '-150%',
          filter: 'blur(160px)',
          opacity: 0,
          autoAlpha: 0,
          ease: 'sine.inOut',
        },
        {
          top: '50%',
          duration: 4.3,
          opacity: 1,
          autoAlpha: 1,
          filter: 'blur(10px)',
          ease: 'sine.inOut',
          onComplete: () => {
            setShowStart(true);
          },
        }
      );
    }

    if (step === 'audio') {
      ascendingTimeline.fromTo(
        shapeRef.current,
        {
          top: 300,
          filter: 'blur(160px)',
          ease: 'sine.inOut',
        },
        {
          top: -60,
          duration: 4,
          filter: 'blur(5px)',
          ease: 'sine.inOut',
        }
      );
    }
  }, [step]);

  return (
    <>
      <Head>
        <title>Nazario Sauro | Audio Tour</title>
      </Head>

      <DefaultLayout
        shapeRef={shapeRef}
        onLoadVideo={onVideoLoaded}
        showActiveAudio={step !== 'audio'}
      >
        {!videoLoaded && <FullScreenLoader />}

        {/* INTRO */}
        {step === 'start' && (
          <Intro
            showStart={showStart}
            introRef={introRef}
            onShowLangStep={onShowLangStep}
          />
        )}

        {/* LANG */}
        {step === 'lang' && (
          <Lang
            langRef={langRef}
            currentLang={currentLang}
            onClickLang={onClickLang}
          />
        )}

        {/* TICKET CODE */}
        {step === 'code' && <Code setStep={setStep} />}

        {/* AUDIO LIST */}
        {step === 'audio' && (
          <>
            <AudioList
              lang={currentLang}
              onMoveUp={(value) => {
                const portion = window.innerHeight / 20;

                console.log({
                  value,
                  duration: (value > 0 ? value : 5) * 0.5,
                  current: portion * value,
                  rotationX: -5,
                });

                gsap.to(shapeRef.current, {
                  ease: 'Back.easeOut.config(1.7)',
                  y: portion * value,
                });
              }}
              onMoveDown={(value) => {
                let portion = window.innerHeight / 20;

                console.log({
                  value,
                  innerHeight: window.innerHeight,
                  portion,
                  current: portion * value,
                });

                gsap.to(shapeRef.current, {
                  ease: 'Back.easeOut.config(1.7)',
                  y: portion * value,
                });
              }}
            />
          </>
        )}
      </DefaultLayout>
    </>
  );
};

export default HomePage;
