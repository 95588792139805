import HeadphonesIcon from '@mui/icons-material/Headphones';
import { alertSelector } from '@slices';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

export default function DefaultLayout({
  children,
  showActiveAudio,
  onLoadVideo,
  shapeRef,
}) {
  const { reload } = useRouter();
  const tick = new Audio('audio/tick.wav');
  const alert = useSelector(alertSelector);

  const onClickLogo = () => {
    tick.play();
    reload();
  };

  return (
    <div
      className={classnames(
        'layout default-layout',
        'relative h-screen w-screen overflow-hidden',
        'bg-ocean bg-cover bg-fixed bg-bottom bg-repeat'
      )}
    >
      <img
        src="sauro.png"
        alt="logo-galata"
        className="absolute left-0 top-0 z-50 w-[6rem] cursor-pointer"
        onClick={onClickLogo}
      />
      {showActiveAudio && (
        <span className="absolute right-8 top-6 z-30 flex select-none flex-col items-center space-y-2 text-white">
          <HeadphonesIcon
            fontSize="medium"
            className="m-0 fill-white p-0 mix-blend-darken"
          />
          <code className="text-xs uppercase">Set audio on</code>
        </span>
      )}
      {children}

      {alert && (
        <div className="fixed top-0 left-0 z-10 h-screen w-screen animate-pulse bg-red-800/80 mix-blend-hue ease-in-out" />
      )}

      <video
        loop
        muted
        autoPlay
        preload="true"
        controls={false}
        className="fixed z-10 min-h-full w-auto min-w-full max-w-none mix-blend-soft-light"
        onLoadedData={onLoadVideo}
      >
        <source src="/liquid.mp4" type="video/mp4" />
      </video>

      <img
        ref={shapeRef}
        src="img/shape.png"
        className="absolute z-30 w-80 mix-blend-multiply"
      />
    </div>
  );
}
